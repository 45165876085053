import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VDialog,{model:{value:(_vm.showAddUser),callback:function ($$v) {_vm.showAddUser=$$v},expression:"showAddUser"}},[_c(VCard,[_c(VCardTitle,[_vm._v("Add a User")]),_c(VCardText,[_c(VTextField,{attrs:{"label":"Email"},model:{value:(_vm.newUser.email),callback:function ($$v) {_vm.$set(_vm.newUser, "email", $$v)},expression:"newUser.email"}}),_c(VTextField,{attrs:{"label":"Password","type":"password"},model:{value:(_vm.newUser.password),callback:function ($$v) {_vm.$set(_vm.newUser, "password", $$v)},expression:"newUser.password"}}),_c(VTextField,{attrs:{"label":"First Name","type":"text"},model:{value:(_vm.newUser.firstName),callback:function ($$v) {_vm.$set(_vm.newUser, "firstName", $$v)},expression:"newUser.firstName"}}),_c(VTextField,{attrs:{"label":"Last Name","type":"text"},model:{value:(_vm.newUser.lastName),callback:function ($$v) {_vm.$set(_vm.newUser, "lastName", $$v)},expression:"newUser.lastName"}})],1),_c(VCardActions,[_c(VBtn,{on:{"click":function($event){_vm.showAddUser=false}}},[_vm._v("Cancel")]),_c(VSpacer),_c(VBtn,{attrs:{"loading":_vm.addingUser},on:{"click":function($event){return _vm.addNewUser(_vm.newUser)}}},[_vm._v("Add User")])],1)],1)],1),_c(VCardTitle,[_vm._v("Users "),_c(VSpacer),_c(VBtn,{attrs:{"outlined":""},on:{"click":function($event){_vm.showAddUser=true}}},[_vm._v("Add User")])],1),_c(VCardText,[_c(VRow,[_c(VSpacer),_c(VCheckbox,{attrs:{"label":"Show Deleted Users"},model:{value:(_vm.showDeleted),callback:function ($$v) {_vm.showDeleted=$$v},expression:"showDeleted"}})],1),_c(VDataTable,{attrs:{"items":_vm.filteredUsers,"headers":_vm.headers,"disable-pagination":"","hide-default-footer":"","sort-by":"first_name"},scopedSlots:_vm._u([{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":("mailto:" + (item.email))}},[_vm._v(_vm._s(item.email))])]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [_c(VSelect,{staticClass:"ma-0 pa-0",attrs:{"disabled":_vm.readOnly,"solo":"","flat":"","items":_vm.roles,"item-text":"name","item-value":"id","hide-details":""},on:{"change":function($event){return _vm.saveRole(item)}},model:{value:(item.role),callback:function ($$v) {_vm.$set(item, "role", $$v)},expression:"item.role"}})]}},{key:"item.streams",fn:function(ref){
var item = ref.item;
return [_c(VSelect,{staticClass:"ma-0 pa-0",attrs:{"disabled":_vm.readOnly,"hide-details":"","solo":"","flat":"","item-text":"name","item-value":"stream","items":_vm.diets,"multiple":"","chips":"","clearable":""},on:{"change":function($event){return _vm.saveStreams(item)}},model:{value:(item.streams),callback:function ($$v) {_vm.$set(item, "streams", $$v)},expression:"item.streams"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(!_vm.readOnly)?_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteUser(item)}}},[_c(VIcon,[_vm._v("mdi-trash-can")])],1):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }