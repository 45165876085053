<template>
  <v-card>
    <v-dialog v-model="showAddUser">
      <v-card>
        <v-card-title>Add a User</v-card-title>
        <v-card-text>
          <v-text-field
            label="Email"
            v-model="newUser.email"
          />
          <v-text-field
            label="Password"
            v-model="newUser.password"
            type="password"
          />
          <v-text-field
            label="First Name"
            v-model="newUser.firstName"
            type="text"
          />
          <v-text-field
            label="Last Name"
            v-model="newUser.lastName"
            type="text"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn @click="showAddUser=false">Cancel</v-btn>
          <v-spacer/>
          <v-btn :loading="addingUser" @click="addNewUser(newUser)">Add User</v-btn>
        </v-card-actions>

      </v-card>

    </v-dialog>
    <v-card-title>Users
      <v-spacer/>
      <v-btn outlined @click="showAddUser=true">Add User</v-btn>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-spacer/>
        <v-checkbox v-model="showDeleted" label="Show Deleted Users"/>
      </v-row>
      <v-data-table
        :items="filteredUsers"
        :headers="headers"
        disable-pagination
        hide-default-footer
        sort-by="first_name"
      >
        <template v-slot:item.email="{item}">
          <a :href="`mailto:${item.email}`">{{ item.email }}</a>
        </template>
        <template v-slot:item.role="{item}">
          <v-select
            :disabled="readOnly"
            class="ma-0 pa-0"
            solo flat
            v-model="item.role"
            :items="roles"
            item-text="name"
            item-value="id"
            @change="saveRole(item)"
            hide-details
          />
        </template>
        <template v-slot:item.streams="{item}">
          <v-select
            :disabled="readOnly"
            class="ma-0 pa-0"
            hide-details
            solo flat
            v-model="item.streams"
            item-text="name"
            item-value="stream"
            :items="diets"
            multiple
            chips
            clearable
            @change="saveStreams(item)"
          />
        </template>
        <template v-slot:item.actions="{item}">
          <!-- TODO: need to implement deleteUser method -->
          <v-btn
            v-if="!readOnly"
            icon @click="deleteUser(item)">
            <v-icon>mdi-trash-can</v-icon>
          </v-btn>
        </template>

      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import {mapGetters} from 'vuex';
import api from '@/api';

export default {
  name: "Users",
  data() {
    return {
      users: [],
      roles: [],
      diets: [],
      headers: 'id,first_name,last_name,email,role,streams'
        .split(',')
        .map(p => ({value: p, text: p})),
      showDeleted: false,
      readOnly: false,
      showAddUser: null,
      newUser: {},
      addingUser: null
    }
  },
  created() {
    this.readOnly = !!this.$route.meta.readOnly;
  },
  mounted() {
    api.get('users/roles')
      .then(response => {
        this.roles = response.data.sort((a, b) => a.name.localeCompare(b.name));
      })
    api.get('diets')
      .then(response => {
        this.diets = response.data.sort();
      })
    this.fetchUsers();
  },
  computed: {
    ...mapGetters([
      'getCurrentUser'
    ]),
    user() {
      console.log('user ', this.getCurrentUser);
      return this.getCurrentUser;
    },
    deletedRoleId() {
      const role = this.roles.find(r => r.name === 'deleted');
      return role && role.id;
    },
    filteredUsers() {
      if (this.showDeleted) {
        return this.users.filter(u => u.role === this.deletedRoleId)
      } else {
        return this.users.filter(u => u.role !== this.deletedRoleId)
      }
    },
  },
  methods: {
    saveStreams(user) {
      console.log('saving', JSON.stringify(user));
      const streams = user.streams.split ? user.streams.split(',').map(s => s.trim()) : user.streams;
      api.post('users/streams', {
        user: user.id,
        streams
      })
    },
    saveRole(user) {
      console.log('saving', JSON.stringify(user));
      api.post('users/role', {
        user: user.id,
        role: user.role
      })
    },
    async addNewUser(user) {
      this.addingUser = true;
      user.userName = user.userName || user.email;
      await api.post('users/register', user)
        .then(r => console.log('result', r))
        .catch(e => {
          console.warn('failed', e);
          this.addingUser = false;
        })
        .then(() => {
          this.showAddUser = false;
          this.addingUser = false;
          return this.fetchUsers();
        });

    },
    fetchUsers() {
      return api.get('users/list')
        .then(response => {
          this.users = response.data;
          this.users.forEach(u => u.streams = u.streams ? u.streams : []);
        })

    }
  }
}
</script>

<style scoped>

</style>
